<template >
  <div>

    <!--    修改信息弹窗-->
    <el-dialog

      :fullscreen="true"
      ref="form"
      title="修改信息"
      align="center"
      :visible.sync="ifEdit"
      :show-close="false"
      width="50%">
      <div style="width: 60%">
        <el-form ref="form" :model="form" :rules="editRule" label-width="15%">
          <el-form-item label="律所名称" >
            <el-input v-model="form.dname" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="组织机构代码证" >
            <el-input v-model="form.practiceno" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="成立时间" >
            <el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="form.birthday" style="width: 100%;"></el-date-picker>

<!--            <el-input :placeholder="form.birthday" disabled></el-input>-->
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="form.phone" maxlength="11"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="主任">
            <el-input v-model="form.director" maxlength="10"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="内勤" prop="staffName">
            <el-input v-model="form.staffName" maxlength="10"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="内勤手机号" prop="staffPhone">
            <el-input v-model="form.staffPhone" maxlength="11"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="地址"  prop="address">
            <el-input v-model="form.address" maxlength="50"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="邮箱"  prop="email">
            <el-input v-model="form.email"  maxlength="32"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="邮编" prop="postcode" maxlength="6"  show-word-limit>
            <el-input v-model="form.postcode"></el-input>
          </el-form-item>



        </el-form>
        <span slot="footer" class="dialog-footer">
             <el-button size="small" type="primary" @click="editSubmit('form')">确定修改</el-button>
          <el-button size="small" @click="ifEdit = false">取消</el-button>
          </span>

      </div>

    </el-dialog>

    <!--    修改密码弹窗-->
    <el-dialog
      title="修改密码"
      :show-close="false"
      :visible.sync="ifModifyPwd"
      width="40%"
      :close-on-press-escape="false"
      :close-on-click-modal="false">

      <div>密码必须由字母和数字两种组成，长度为6-10位，字母不区分大小写</div>
      <br>
      <br>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
<!--        <el-form-item  label="当前密码" prop="curPass">-->
<!--          <el-input type="password" v-model="ruleForm.curPass" auto-complete="off"> </el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="新密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="确认密码" prop="checkPass" required>
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
              <el-button size="small" type="primary" @click="submitEditPwd('ruleForm')">确定</el-button>
          <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
          </span>
    </el-dialog>

    <!--    主体显示-->
    <el-row>
      <el-col :span="6" :push="6">
        <h3>律所名称</h3>
        <span>{{adminInformation.dname}}</span>
      </el-col>
            <el-col :span="6" :push="6">
              <h3>事务执业证编号：</h3>
              <span>{{adminInformation.practiceno}}</span>
            </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="6" :push="6">
        <h3>成立时间</h3>
        <span>{{adminInformation.birthday}}</span>
      </el-col>
      <el-col :span="6" :push="6">
        <h3>联系电话</h3>
        <span>{{adminInformation.phone}}</span>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="6" :push="6">
        <h3>主任</h3>
        <span>{{adminInformation.director}}</span>
      </el-col>
      <el-col :span="6" :push="6">
        <h3>邮箱</h3>
        <span>{{adminInformation.email}}</span>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="6" :push="6">
        <h3>内勤</h3>
        <span>{{adminInformation.staffName}}</span>
      </el-col>
      <el-col :span="6" :push="6">
        <h3>内勤手机号</h3>
        <span>{{adminInformation.staffPhone}}</span>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="6" :push="6">
        <h3>地址</h3>
        <span>{{adminInformation.address}}</span>
      </el-col>
      <el-col :span="6" :push="6">
        <h3>邮编</h3>
        <span>{{adminInformation.postcode}}</span>
      </el-col>
    </el-row>
    <el-divider></el-divider>

    <el-row>
      <el-col :span="6" :push="6">
        <el-button
          align="center"
          size="small"
          type="warning"
          icon="el-icon-edit"
          @click="handleEdit()">编辑信息</el-button>
      </el-col>
      <el-col :span="6" :push="6">
        <el-button
          align="center"
          size="small"
          type="danger"
          icon="el-icon-refresh"
          @click="handleEditPwd()">修改密码</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { request } from '@/network/network'
import {postCode} from '@/utils/rules'
import { password } from '@/utils/rules'

export default {
  data() {
    var validateCurPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      adminInformation:{},
      ifEdit: false,
      ifModifyPwd: false,
      form: {},
      ruleForm: {
        curPass: '',
        pass: '',
        checkPass: '',
      },
      editRule:{
        postcode: postCode,
        email: [ { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }],
      },

      rules: {
        curPass: [
          { validator: validateCurPass, trigger: 'blur' }
        ],
        // pass: [
        //   { validator: validatePass, trigger: 'blur' }
        // ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        pass:password

      },
    };
  },
  methods: {
    handleEdit() {
      this.form = JSON.parse(JSON.stringify(this.adminInformation))
      this.ifEdit=true
    },
    handleEditPwd() {
      this.ifModifyPwd=true
    },
    editSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.form.birthday=null
          this.form.id = this.adminInformation.did
          this.updateDep(this.form)
            .then(res=>{
              this.ifEdit=false
              this.$message({
                message: "修改成功,请重新登录",
                type: "success",
                duration: 1000,
              })
              setTimeout(() => {
                this.$router.push("/login")
              }, 1100)
            })
            .catch(err => {
              this.ifEdit = false
              this.$message({
                message: '发生错误，修改失败！',
                type: 'error',
                duration: 1000
              })
              setTimeout(() => {
                this.$router.go(0)
              }, 500)
            })


        } else {
          console.log('error submit!!');
          return false;
        }
      });






    },
    submitEditPwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let admin = {"id":this.adminInformation.id, "password":this.ruleForm.pass}
          console.log(admin)
          this.updateAdmin(admin)
            .then(res=>{
              this.$refs[formName].resetFields();
              this.ifModifyPwd=false
              this.$message({
                message: "修改成功,请重新登录！",
                type: "success",
                duration: 1000,
              })
              setTimeout(() => {
                this.$router.push("/login")
              }, 1100)
            })
            .catch(err => {
              this.ifModifyPwd = false
              this.$message({
                message: '发生错误，修改失败！',
                type: 'error',
                duration: 1000
              })
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //取消修改密码
    resetForm(formName) {
      this.ifModifyPwd = false
      this.$refs[formName].resetFields();
    },
    updateAdmin(admin) {
      return request({
        url: '/admin/updateAdmin',
        method: 'post',
        data: admin
      })
    },
    updateDep(department) {
      return request({
        url: '/department/updateDep',
        method: 'post',
        data: department
      })
    }

  },
  created() {
    this.adminInformation = JSON.parse(sessionStorage.getItem("information"))
  }
}
</script>
<style>

</style>




